import React,  { Component } from 'react';
import stylish from 'tiny-stylish-components';

const SVG = stylish.svg`
  width: 5rem;
  height: 5rem;
  &:hover {
    fill: #2AA3EF;
  }
`;

module.exports = () => {
  return <SVG viewBox="0 0 250 250">
    <path d="M218,63.4c-4.3,1.9-8.7,3.4-13.2,4.5c5-4.8,8.8-10.7,10.9-17.3l0,0c0.6-1.9-1.5-3.5-3.2-2.5l0,0c-6.3,3.7-13.1,6.6-20.2,8.4
      c-0.4,0.1-0.8,0.2-1.3,0.2c-1.3,0-2.6-0.5-3.5-1.4c-7.6-6.7-17.3-10.3-27.4-10.3c-4.4,0-8.8,0.7-13.1,2c-13.4,4.2-23.7,15.2-27,28.9
      c-1.2,5.1-1.5,10.2-1,15.2c0.1,0.6-0.2,1-0.4,1.2c-0.3,0.3-0.7,0.5-1.1,0.5c0,0-0.1,0-0.1,0C88,89.9,61.6,75.7,42.9,52.8l0,0
      c-1-1.2-2.8-1-3.5,0.3l0,0c-3.7,6.3-5.6,13.5-5.6,20.8c0,11.2,4.5,21.8,12.3,29.5c-3.3-0.8-6.5-2-9.4-3.7l0,0
      c-1.4-0.8-3.2,0.2-3.2,1.9l0,0c-0.2,16.6,9.5,31.5,24.1,38.1c-0.3,0-0.6,0-0.9,0c-2.3,0-4.7-0.2-7-0.7l0,0c-1.6-0.3-3,1.2-2.5,2.8
      l0,0C52,156.6,64.7,167.5,80,170c-12.7,8.5-27.4,13-42.7,13l-4.8,0c-1.5,0-2.7,1-3.1,2.4c-0.4,1.4,0.3,2.9,1.6,3.6
      c17.3,10.1,37,15.4,57,15.4c17.5,0,33.9-3.5,48.7-10.3c13.6-6.3,25.6-15.3,35.6-26.7c9.4-10.7,16.7-23,21.8-36.6
      c4.9-13,7.4-26.8,7.4-40v-0.6c0-2.1,1-4.1,2.6-5.5c6.4-5.2,11.9-11.2,16.4-18l0,0C221.8,64.8,220,62.5,218,63.4L218,63.4z"/>
  </SVG>
  ;
}