import React,  { Component } from 'react';
import stylish from 'tiny-stylish-components';

const SVG = stylish.svg`
  width: 5rem;
  height: 5rem;
  &:hover {
    fill: #00FD8D;
  }
`;

module.exports = () => {
  return <SVG viewBox="0 0 250 250">
    <path d="M172.1,45.3c2.7,6.1,4.4,16.3,1.8,23.7c10.7,9.1,14.3,31.2,9.2,49.3c15.2,1.2,33.3-0.6,45,4.1
      c-10.9-1.4-25.8-4.2-39.9-3.2c-2.7,0.2-6.6-0.1-6.4,3.7c16.3,1.3,32.5,2.7,45.9,6.9c-13.3-2.2-29.4-5.5-45.9-5.9
      c-6.8,13.4-20.5,20.1-39.5,21.5c2,4.4,5.9,6.1,6.9,13.2c1.4,10.6-2.2,26.2,1.4,33.3c1.7,3.4,4.5,3.5,6.4,6.4
      c-4.6,5.5-16.1-0.6-17.4-6.4c-2.2-9.9,3.4-25.2-2.7-32c0.4,10.8-2.6,26,0.5,35.6c1.2,3.8,4.6,5.3,3.7,8.7
      c-21.6,2.2-12.5-27.9-16.1-45.7c-3.3,0.2-1.8,4.9-1.8,6.9c-0.1,17.3,3.5,41.1-15.1,38.8c-0.5-3.6,2.5-4.9,3.7-8.2
      c3.4-9.8-0.6-24.3,0.9-36.1c-7.1,5.4,0.7,24.2-3.7,33.8c-2.5,5.5-10.8,8-17,5.5c0.8-4.1,5.1-3.5,6.9-7.3c2.5-5.4,0-13.1,0.9-21
      c-13.2,2.6-23.4-0.4-28.5-8.7c-2.2-3.7-2.8-8.1-5.5-11.4c-2.7-3.3-7-3.7-8.3-8.2c16.4-3.9,17,16.8,31.7,17.3
      c4.5,0.2,6.8-1.3,11-2.3c1.2-5.2,3.7-9.1,7.3-11.9c-18.3-2.5-33.4-8.2-40.4-21.9c-16.6,0.8-31.8,3-45.9,6.4
      c12.8-4.6,28.5-6.3,45.4-6.9c-1-6-9.7-4.3-15.1-4.1c-10.1,0.4-22.7,1.7-30.8,3.2c11.6-4.1,27.9-3.6,43.6-3.7
      c-4.8-15.1-3.1-39,7.3-47.9c-3-7.5-2-19.7,1.8-25.6c11.7,0.5,18.8,5.6,26.2,10.5c9.1-2.6,18.7-3.9,31.2-3.2
      c5.3,0.3,10.7,2.7,14.7,2.3c3.9-0.4,8.2-4.7,11.9-6.4C162.3,46.3,166.5,45.5,172.1,45.3z"/>
  </SVG>
  ;
}